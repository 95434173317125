import React, { createContext, useState } from 'react';
import { backUserLogin, backUserResetPassword, backUserRenewPassword, backpreferences, backUserValidateToken } from '../backend/UserFunctions';
import axios from 'axios';
import { defaultLinksFuntionsOn, defaultLinksUserOn, initialLinksBar } from './configs/constants';
import Enviroment from './configs/enviroment';
import dayjs from 'dayjs';
import { createClient } from '../backend/ClientFunctions';
import { findChannelsTotal } from 'backend/ChannelFunctions';

/**
 * Axios HTTP Request defaults
*/
axios.defaults.baseURL = Enviroment.api.url;
axios.defaults.headers.common['Content-Type'] = 'application/json';





export const AppContext = createContext();

const AppInternalProvider = ({children}) => {
    const [user, setUser] = useState({authenticate : false, localValidate: true});
    const [preferences, setPreferences] = useState({start: false, logout: false});
    const [menuLinks, setMenuLinks] = useState(initialLinksBar);
    const [initialDateFilter, setInitialDateFilter] = useState(dayjs().add(-15, 'day'));
    const [finalDateFilter, setFinalDateFilter] = useState(dayjs().add(+15, 'day'));
    const [channelsTotal, setChannelsTotal] = useState(null);

    const setMenuUser = (user) => {
        var links = menuLinks;
        links.userLinks = defaultLinksUserOn;
        links.functionLinks = defaultLinksFuntionsOn;
        setMenuLinks(links);
    }

    const updateUser = (newUser) => {
        const newProspsUser = {
            photoURL : "/assets/images/avatars/avatar_15.jpg",
            displayName: newUser.name,
            role: "Admin"
        }
        const updatedUser = {...newUser, ...newProspsUser};

        setUser(updatedUser);
    }


    const BackUserFunctions = {
        login : (email, password) => {
            return backUserLogin({email, password}).then((backUser) => {
                console.log({backUser});
                if (backUser.authenticate) {
                    localStorage.setItem('token', backUser.token);
                }
                updateUser(backUser);
                setMenuUser(backUser);

                return backUser;
            });
        },

        resetPassword : (email) => {
            return backUserResetPassword({email}).then((resp) => {
                console.log(resp);
                return resp;
            })
        },

        renewPassword : (email, password, newpassword, confirmpassword) => {
            return backUserRenewPassword({email, password, newpassword, confirmpassword}).then((resp) => {
                console.log(resp);
                return resp;
            })
        },

        validate: (token) => {
            return backUserValidateToken(token).then((resp) => {
                console.log(resp);
                return resp;
            })
        }


    }

    const BackAppFunctions = {
        GetPreferences : () => {
            if (preferences.start === false) {
                const newPreference = {start : true};
                setPreferences(newPreference);
                BackAppFunctions.InitByToken();
                return backpreferences().then((backPreferences) => {
                    console.log({backPreferences});
                    const newPreference = {...backPreferences.preferences, start : true};
                    setPreferences(newPreference);
                    return newPreference;
                })
            } else {
                return preferences;
            } 
        },

        InitByToken: () => {
            const token = localStorage.getItem("token");
            if ((token !== null) && (token !== '')) {
                backUserValidateToken(token).then(validateToken => {
                    console.log({validateToken});
                    if (Boolean(validateToken.user)){
                        updateUser(validateToken.user);
                        setMenuUser(validateToken.user);
                    }

                })
            } else {
                var newUser = user;
                delete newUser.localValidate;
                updateUser(newUser);
            }
        },

        Logout: async () => {
            if (preferences.logout === false) {
                const newPreference = {logout : true};
                setPreferences({...preferences, ...newPreference});
                return {};

            } else {
                return {};
            }
        },

        createClient: (nameCompany, cpfCnpj, name, email, phone) => {
            return createClient(nameCompany, cpfCnpj, name, email, phone).then((resp) => {
                console.log(resp);
                return resp;
            })
        },

        GetChannelsTotal: (user, dataInicial, dataFinal) => {
            return findChannelsTotal({user, dataInicial, dataFinal}).then((resp) => {
                console.log(resp);
                setChannelsTotal(resp);
                return resp;
            })
        }

    }


    

    return (
        <AppContext.Provider value={{
            user,
            preferences,
            initialDateFilter,
            finalDateFilter,
            menuLinks,
            channelsTotal,
            BackUserFunctions,
            BackAppFunctions,
            setFinalDateFilter,
            setInitialDateFilter
        }}>
            {children}
        </AppContext.Provider>
    );
}

export const AppProvider = React.memo(AppInternalProvider);

