
const Enviroment = { 
  api : {
    url: 'https://api.grupoconexo.com.br/app',
  },

  openPaths : ["/login", "/renewpassword", "/resetpassword", "/createclient"]

}



export default Enviroment;
