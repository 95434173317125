// component

import { CurrencyExchange, Dataset, InsertChart, Logout } from "@mui/icons-material";
import SvgColor from "components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Resultados Globais',
    path: '/dashboard/global',
    icon: <InsertChart />,
  },
  {
    title: 'Evoluções',
    path: '/dashboard/evolutions',
    icon: icon('ic_analytics'),
  },  
  {
    title: 'Resultados por Eventos',
    path: '/dashboard/events',
    icon: <Dataset />,
  },
  {
    title: 'Resultados Financeiros',
    path: '/dashboard/finance',
    icon: <CurrencyExchange />,
  },
  {
    title: 'Sair',
    path: '/logout',
    icon: <Logout />,
  },
];

export default navConfig;
