
export default function EventsPage() {

  return (
    <>
          <p>Resultado por tipo de Evento</p>
          <b>INSTA</b>
          <p>- Live X no Insta - total de visualizações, comentários, cliques, aviõeszinhos, coraçãozinho</p>
          <p>- eventos de origem (email, whatsapp, direct, desconhecidos)</p>
          <p>- eventos de destino - lp, checkout, etc</p>
          <b>YOUTUBE</b>
          <p>- Live X no YOUTUBE - total de visualizações, comentários, cliques, </p>
          <p>- eventos de origem (email, whatsapp, direct, desconhecidos)</p>
          <p>- eventos de destino - lp, checkout, etc</p>
          <b>SESSÃO ESTRATÉGICA</b>
          <p>- TOTAL DE AGENDAMENTOS - total de SESSÕES - COMPROMISSOS DE COMPRA - DESISTENCIA - ETC</p>
          <p>- eventos de origem (email, whatsapp, direct, desconhecidos)</p>
          <p>- eventos de destino - lp, checkout, etc</p>
          <b>CONVERSA NO DIRECT/WHATS</b>
          <p> total de conversas, total de mensagens, conversas iniciadas, conversas interagidas</p>
          <p>- eventos de origem (email, lp)</p>
          <p>- eventos de destino - lp, checkout, sessão estratégicas</p>
          <b>checkout produto X</b>
          <p>- Views / leads identificados / compras - total de carrinho abandonado</p>
          <p>- eventos de origem (sessão estratégica, live x, lp tal, email, whatsapp)</p>

          <b>Traffego campanha x</b>
          <p> - Criativos - numeros por criativos
          <p>- eventos de destino - lp view, lp cadastro, direct conversas </p>

          </p>


    </>);
}

