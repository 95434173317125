import { faker } from '@faker-js/faker';
import { set, sub } from 'date-fns';

export const defaultLinksUserOff = [
    {label: "Login", type: 'navigate', url: "/login"}
];
export const defaultLinksUserOn = [
    {label: "Sair", type: 'navigate', url: "/logout"},
    {label: "Alterar Senha", type: 'navigate', url: "/renewpassword"}
];

export const defaultLinksFunctionsOff = [
    {label: 'Preços', type: 'link', url: "/precos"}, 
    {label: 'Blog', type: 'link', url: "/blog"}, 
];

export const defaultLinksFuntionsOn = [
    {label: 'Relatórios', type: 'navigate', url: "/reports"}, 
]


export const initialLinksBar = {
    functionLinks : defaultLinksFunctionsOff,
    userLinks : defaultLinksUserOff, 
}

export const AppConstants = {
    nameApp: "Laance Controll",

    routePaths : {
        defaultPath : "/dashboard",
        login : "/login",
        logout: "/logout",
        renewPassword : "/renewpassword",
        resetPassword : "/resetpassword",
        createUser : "/createclient",
        dashboard : "/dashboard",

        reports: "/reports"
        
    }
}



export const NOTIFICATIONS = [];

export const NOTIFICATIONS_MOCK = [
  {
    id: faker.datatype.uuid(),
    title: 'Your order is placed',
    description: 'waiting for shipping',
    avatar: null,
    type: 'order_placed',
    createdAt: set(new Date(), { hours: 10, minutes: 30 }),
    isUnRead: true,
  },
  {
    id: faker.datatype.uuid(),
    title: faker.name.fullName(),
    description: 'answered to your comment on the Minimal',
    avatar: '/assets/images/avatars/avatar_2.jpg',
    type: 'friend_interactive',
    createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
    isUnRead: true,
  },
  {
    id: faker.datatype.uuid(),
    title: 'You have new message',
    description: '5 unread messages',
    avatar: null,
    type: 'chat_message',
    createdAt: sub(new Date(), { days: 1, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
  {
    id: faker.datatype.uuid(),
    title: 'You have new mail',
    description: 'sent from Guido Padberg',
    avatar: null,
    type: 'mail',
    createdAt: sub(new Date(), { days: 2, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
  {
    id: faker.datatype.uuid(),
    title: 'Delivery processing',
    description: 'Your order is being shipped',
    avatar: null,
    type: 'order_shipped',
    createdAt: sub(new Date(), { days: 3, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
];
