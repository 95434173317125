import { LoadingButton } from "@mui/lab";
import { CircularProgress, Container, Grid, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AppContext } from "context/AppContext";
import { useContext,  useEffect,  useState } from "react";
import AppWidgetSummary from "sections/app/AppWidgetSummary";

export default function GlobalPage() {
  const {initialDateFilter, finalDateFilter, 
    setInitialDateFilter, setFinalDateFilter, 
    user, BackAppFunctions, channelsTotal} = useContext(AppContext);

    const [carregando, setCarregando] = useState(false);
    const [inicializou, setInicializou] = useState(false);

  useEffect(() => {
    if (!inicializou) {
        setInicializou(true);
    
        BackAppFunctions.GetChannelsTotal(user, initialDateFilter, finalDateFilter).then(() => {
            //setInicializou(false);
        });
    }

  }, [BackAppFunctions, inicializou, user, initialDateFilter, finalDateFilter]);

  const findGlobalData = () => {
    setCarregando(true);
    BackAppFunctions.GetChannelsTotal(user, initialDateFilter, finalDateFilter).then(() => {
        setCarregando(false);
    });
  }

  const RenderTotals = () => {

    const Sumarys = ({list}) => {
        console.log({list});
        return list.map((item, j) => {
            return (
                <Grid item key={j} xs={12} sm={6} md={3}>
                    <AppWidgetSummary  
                        title={<> 
                                <h2>{item.typeMetric}</h2>
                            </>} 
                        total={item.totalMetric} 
                        color={item.colorTheme} 
                        icon={'ant-design:home-filled'} />
                </Grid>
            );
        });

    }


    if (Boolean(channelsTotal)){
        return Object.keys(channelsTotal.channels).map((channelType, i) => {
            return (
                <Grid key={i} container spacing={3}>
                    <Grid item xs={12}>{channelsTotal.channels[channelType][0].channelDesc}</Grid>
                    <Sumarys list={channelsTotal.channels[channelType]} />
                </Grid>
            )
            });
    } else {
        return <><CircularProgress /> </>
    }
  }


  return (
    <Container maxWidth="xl"  >
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    Olá, bem vindo! 
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}><DateTimePicker label="Início" value={initialDateFilter} onChange={(newValue) => setInitialDateFilter(newValue)}  slotProps={{ textField: { fullWidth: true } }} /></Grid>
                    <Grid item xs={12} sm={4}><DateTimePicker label="Final" value={finalDateFilter} onChange={(newValue) => setFinalDateFilter(newValue)} slotProps={{ textField: { fullWidth: true } }} /></Grid>
                    <Grid item xs={12} sm={4}>
                        <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={findGlobalData}  loading={carregando} loadingIndicator="Carregando…">
                            Buscar 
                        </LoadingButton >
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        <RenderTotals />


    </Container>
  );
}

/**
 * 
 * 
        <Grid container spacing={3}>
            <Grid item xs={12}>Facebook</Grid>
            <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Novas Curtidas FB" total={100} color="facebook" icon={'ant-design:home-filled'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Novos Seguidores FB" total={125} color="info" icon={'ant-design:apple-filled'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Novos Seguidores Instagram" total={1723315} color="instagram" icon={'ant-design:windows-filled'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Comentarios Direct" total={234} color="instagram" icon={'ant-design:bug-filled'} />
            </Grid>

            <Grid item xs={12}>Instagram</Grid>
            <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Emails Enviados" total={100} color="facebook" icon={'ant-design:home-filled'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Emails Lidos" total={125} color="info" icon={'ant-design:apple-filled'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Conversas Whatsapp" total={1723315} color="instagram" icon={'ant-design:windows-filled'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Comentarios Direct" total={234} color="instagram" icon={'ant-design:bug-filled'} />
            </Grid>

            <Grid item xs={12}>Whatsapp</Grid>
            <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Emails Enviados" total={100} color="facebook" icon={'ant-design:home-filled'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Emails Lidos" total={125} color="info" icon={'ant-design:apple-filled'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Conversas Whatsapp" total={1723315} color="instagram" icon={'ant-design:windows-filled'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Comentarios Direct" total={234} color="instagram" icon={'ant-design:bug-filled'} />
            </Grid>

            <Grid item xs={12}>Emails</Grid>
            <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Emails Enviados" total={100} color="facebook" icon={'ant-design:home-filled'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Emails Lidos" total={125} color="info" icon={'ant-design:apple-filled'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Conversas Whatsapp" total={1723315} color="instagram" icon={'ant-design:windows-filled'} />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Comentarios Direct" total={234} color="instagram" icon={'ant-design:bug-filled'} />
            </Grid>


        </Grid>

 */