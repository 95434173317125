
export default function EvolutionsPage() {

  return (
          <>
          grafico com Evoluções dos Numeros ao longo do Tempo

          <p>- Seguidores Instagram</p>
          <p>- Seguidores Facebook</p>
          <p>- Quantidade de Leads</p>
          <p>- Mensagens Direct</p>
          <p>- Conversas Direct</p>
          <p>- Mensagens Whatsapp</p>
          <p>- Conversas Whatsapp</p>
          
          </>
  );
}

