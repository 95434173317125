import { Navigate, useRoutes } from 'react-router-dom';
import DashboardPage from './pages/DashboardPage';
import LoginPage from './pages/LoginPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import RenewPasswordPage from './pages/RenewPasswordPage';
import LogoutPage from './pages/LogoutPage';
import CreateClientPage from './pages/CreateClientPage';
import { AppConstants } from './context/configs/constants';
import ReportsPage from './pages/ReportsPage';
import GlobalPage from 'pages/GlobalPage';
import EventsPage from 'pages/EventsPage';
import FinancePage from 'pages/FinancePage';
import EvolutionsPage from 'pages/EvolutionsPage';
// layouts

export default function Router() {
  const routes = useRoutes([
    {
      path: AppConstants.routePaths.dashboard,
      element: <DashboardPage />,
      children: [
        { element: <Navigate to="/dashboard/global" />, index: true },
        { path: 'global', element: <GlobalPage /> },
        { path: 'events', element: <EventsPage /> },
        { path: 'finance', element: <FinancePage /> },
        { path: 'evolutions', element: <EvolutionsPage /> },
        
      ],
    },
    {
      path: AppConstants.routePaths.logout,
      element: <LogoutPage />,
    },
    {
      path: AppConstants.routePaths.login,
      element: <LoginPage />,
    },
    {
      path: AppConstants.routePaths.resetPassword,
      element: <ResetPasswordPage />,
    },
    {
      path: AppConstants.routePaths.renewPassword,
      element: <RenewPasswordPage />,
    },
    {
      path: AppConstants.routePaths.createUser,
      element: <CreateClientPage />,
    },
    {
      path: AppConstants.routePaths.reports,
      element: <ReportsPage />,
    },
    
    
    {
      path: '',
      element: <LoginPage />,
    },
    {
      path: '*',
      element: <DashboardPage />,
    },

    

  ]);

  return routes;
}

// ----------------------------------------------------------------------
/*
export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
  */
