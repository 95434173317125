
export default function FinancePage() {

  return (
          <>
          <p>Resultados financeiros</p>
          <b> - Gastos (Traffego) </b>
          <p>Resultado por criativo: Impressão, cliques, conversões (leads e vendas)</p>
          <p>link do criativo</p>

          <b> - Faturamento </b>
          <p>Resultado por Produto</p>


          
          </>
  );
}

